import { constants } from '@cloudbase/utilities'

// @ts-ignore
const { setSdkName: setUtilitiesSdkName, setProtocol: setUtilitiesProtocol } = constants
/**
 * SDK
 */
let sdkVersion = ''
let sdkName = '@cloudbase/js-sdk'

export function setSdkVersion(version: string) {
  sdkVersion = version
}
export function getSdkVersion() {
  return sdkVersion
}
export function setSdkName(name: string) {
  sdkName = name
  setUtilitiesSdkName(name)
}
export function getSdkName() {
  return sdkName
}
export const DATA_VERSION = '2020-01-10'

let PROTOCOL = 'https:'

let BASE_URL = typeof process !== 'undefined' && process.env.NODE_ENV === 'e2e' && process.env.END_POINT === 'pre'
  ? '//tcb-pre.tencentcloudapi.com/web'
  : '//tcb-api.tencentcloudapi.com/web'
export function setEndPoint(url: string, protocol?: 'http' | 'https') {
  BASE_URL = url
  if (protocol) {
    PROTOCOL = protocol
    setUtilitiesProtocol(protocol)
  }
}
export function setRegionLevelEndpoint(env: string, region: string, protocol?: 'http' | 'https') {
  const endpoiont = region
    ? `//${env}.${region}.tcb-api.tencentcloudapi.com/web`
    : `//${env}.ap-shanghai.tcb-api.tencentcloudapi.com/web`
  setEndPoint(endpoiont, protocol)
}
export function getEndPoint() {
  return { BASE_URL, PROTOCOL }
}


export function getBaseEndPoint() {
  const { PROTOCOL, BASE_URL } = getEndPoint()
  const webEndpoint = `${PROTOCOL}${BASE_URL}`
  // @todo 临时兼容小程序
  return webEndpoint.match(/(http(s)?:)?\/\/([^/?#]*)/)[0]
  // return `${new URL(webEndpoint).origin}`
}

export enum LOGINTYPE {
  NULL = 'NULL',
  ANONYMOUS = 'ANONYMOUS',
  WECHAT = 'WECHAT',
  WECHAT_PUBLIC = 'WECHAT-PUBLIC',
  WECHAT_OPEN = 'WECHAT-OPEN',
  CUSTOM = 'CUSTOM',
  EMAIL = 'EMAIL',
  USERNAME = 'USERNAME',
  PHONE = 'PHONE'
}

export const OAUTH2_LOGINTYPE_PREFIX = 'OAUTH2'
