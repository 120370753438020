/**
 * Single Promise
 */
export class SinglePromise {
  private fnPromiseMap: Map<string, Promise<any>> = new Map()
  /**
   * Run single promise.
   * @param {string} key
   * @param {Function} fn
   * @return {Promise<T>}
   */
  async run<T>(key: string, fn: () => Promise<T>): Promise<T> {
    let result: Promise<any> = this.fnPromiseMap.get(key)
    if (!(await result)) {
      result = new Promise<any>((resolve, reject) => {
        (async () => {
          try {
            // The idle promise must be run to prevent _fnPromiseMap from
            // storing the current promise function.
            await this.runIdlePromise()
            const fnResult: Promise<T> = fn()
            resolve(await fnResult)
          } catch (error) {
            reject(error)
          } finally {
            this.fnPromiseMap.delete(key)
          }
        })()
      })
      this.fnPromiseMap.set(key, result)
    }
    return result
  }
  /**
   * Run idle promise.
   * @return {Promise<void>}
   */
  private runIdlePromise(): Promise<void> {
    return Promise.resolve()
  }
}
