'use strict'

import { ApiUrls } from './consts'
import {
  GetVerificationRequest,
  GetVerificationResponse,
  UserProfile,
  UserInfo,
  SignInRequest,
  SignUpRequest,
  VerifyRequest,
  VerifyResponse,
  GenProviderRedirectUriRequest,
  GenProviderRedirectUriResponse,
  GrantProviderTokenRequest,
  GrantProviderTokenResponse,
  PatchProviderTokenRequest,
  PatchProviderTokenResponse,
  SignInWithProviderRequest,
  BindWithProviderRequest,
  TransByProviderRequest,
  GrantTokenRequest,
  UserProfileProvider,
  UnbindProviderRequest,
  CheckPasswordrRequest,
  BindPhoneRequest,
  BindEmailRequest,
  SetPasswordRequest,
  ChangeBindedProviderRequest,
  ChangeBindedProviderResponse,
  UpdatePasswordRequest,
  SudoResponse,
  SudoRequest,
  GetCustomSignTicketFn,
  QueryUserProfileRequest,
  QueryUserProfileResponse,
  ResetPasswordRequest,
  DeviceAuthorizeRequest,
  DeviceAuthorizeResponse,
  CheckUsernameRequest,
  CheckIfUserExistRequest,
  CheckIfUserExistResponse,
  WithSudoRequest,
} from './models'
import { SimpleStorage, RequestFunction } from '../oauth2client/interface'
import { OAuth2Client, defaultStorage } from '../oauth2client/oauth2client'
import { Credentials } from '../oauth2client/models'
import { Captcha } from '../captcha/captcha'


export interface AuthOptions {
  apiOrigin: string;
  clientId: string;
  credentialsClient?: OAuth2Client;
  request?: RequestFunction;
  storage?: SimpleStorage;
  anonymousSignInFunc?: (Credentials) => Promise<Credentials | void>
}

/**
 * Auth
 */
export class Auth {
  private static parseParamsToSearch(params: any): string {
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
    })
    const searchParams = new URLSearchParams(params as any)
    return searchParams.toString()
  }

  private config: AuthOptions
  private getCustomSignTicketFn?: GetCustomSignTicketFn

  /**
   * constructor
   * @param {AuthOptions} opts
   */
  constructor(opts: AuthOptions) {
    let { request } = opts
    let oAuth2Client = opts.credentialsClient
    if (!oAuth2Client) {
      const initOptions = {
        apiOrigin: opts.apiOrigin,
        clientId: opts.clientId,
        storage: opts.storage,
      }
      oAuth2Client = new OAuth2Client(initOptions)
    }
    if (!request) {
      const baseRequest = oAuth2Client.request.bind(oAuth2Client)
      const captcha = new Captcha({
        clientId: opts.clientId,
        request: baseRequest,
        storage: opts.storage,
      })
      request = captcha.request.bind(captcha)
    }
    this.config = {
      apiOrigin: opts.apiOrigin,
      clientId: opts.clientId,
      request,
      credentialsClient: oAuth2Client,
      storage: opts.storage || defaultStorage,
    }
  }

  /**
   * Sign in.
   * @param {SignInRequest} params A SignInRequest Object.
   * @return {Promise<Credentials>} A Promise<Credentials> object.
   */
  public async signIn(params: SignInRequest): Promise<Credentials> {
    const credentials: Credentials = await this.config.request<Credentials>(
      ApiUrls.AUTH_SIGN_IN_URL,
      {
        method: 'POST',
        body: params,
      },
    )
    await this.config.credentialsClient.setCredentials(credentials)
    return Promise.resolve(credentials)
  }

  /**
   * Sign in Anonymously
   * @return {Promise<Credentials>} A Promise<Credentials> object.
   */
  public async signInAnonymously(data: {
    provider_token?: string
  } = {}): Promise<Credentials> {
    const credentials: Credentials = await this.config.request<Credentials>(
      ApiUrls.AUTH_SIGN_IN_ANONYMOUSLY_URL,
      {
        method: 'POST',
        body: data,
      },
    )
    await this.config.credentialsClient.setCredentials(credentials)
    return Promise.resolve(credentials)
  }

  /**
   * Sign up.
   * @param {SignUpRequest} params A SignUpRequest Object.
   * @return {Promise<Credentials>} A Promise<Credentials> object.
   */
  public async signUp(params: SignUpRequest): Promise<Credentials> {
    const data: Credentials = await this.config.request<Credentials>(
      ApiUrls.AUTH_SIGN_UP_URL,
      {
        method: 'POST',
        body: params,
      },
    )
    await this.config.credentialsClient.setCredentials(data)
    return Promise.resolve(data)
  }

  /**
   * Sign out.
   * @return {Object} A Promise<void> object.
   */
  public async signOut(): Promise<any> {
    const accessToken: string = await this.config.credentialsClient.getAccessToken()
    const data = await this.config.request(ApiUrls.AUTH_REVOKE_URL, {
      method: 'POST',
      body: {
        token: accessToken,
      },
    })
    await this.config.credentialsClient.setCredentials()
    return Promise.resolve(data)
  }

  /**
   * Get the verification.
   * @param {GetVerificationRequest} params A GetVerificationRequest Object.
   * @return {Promise<GetVerificationResponse>} A Promise<GetVerificationResponse> object.
   */
  public async getVerification(params: GetVerificationRequest,): Promise<GetVerificationResponse> {
    let withCredentials = false
    // 发送短信时，如果时给当前用户发，则需要带上鉴权信息
    if (params.target === 'CUR_USER') {
      withCredentials = true
    } else {
      const hasLogin = await this.hasLoginState()
      if (hasLogin) {
        withCredentials = true
      }
    }
    return this.config.request<GetVerificationResponse>(
      ApiUrls.VERIFICATION_URL,
      {
        method: 'POST',
        body: params,
        withCaptcha: true,
        withCredentials,
      },
    )
  }

  /**
   *  Verify the code
   * @param {VerifyRequest} params A VerifyRequest Object.
   * @return {Promise<VerifyResponse>} A Promise<VerifyResponse> object.
   */
  public async verify(params: VerifyRequest): Promise<VerifyResponse> {
    return this.config.request<VerifyResponse>(ApiUrls.VERIFY_URL, {
      method: 'POST',
      body: params,
    })
  }

  /**
   * Gen provider redirect uri.
   * @param {GenProviderRedirectUriRequest} params A GenProviderRedirectUriRequest object.
   * @return {Promise<GenProviderRedirectUriResponse>} A Promise<GenProviderRedirectUriResponse> object.
   */
  public async genProviderRedirectUri(params: GenProviderRedirectUriRequest,): Promise<GenProviderRedirectUriResponse> {
    let url = `${ApiUrls.PROVIDER_URI_URL}?client_id=${this.config.clientId
    }&provider_id=${params.provider_id}&redirect_uri=${encodeURIComponent(params.provider_redirect_uri,)}&state=${params.state}`
    const { other_params: otherParams } = params
    if (otherParams) {
      if (
        typeof otherParams.sign_out_uri === 'string'
        && otherParams.sign_out_uri.length > 0
      ) {
        url += `&other_params[sign_out_uri]=${otherParams.sign_out_uri}`
      }
    }
    return this.config.request<GenProviderRedirectUriResponse>(url, {
      method: 'GET',
    })
  }

  /**
   * Grant provider token.
   * @param {GrantProviderTokenRequest} params A GrantProviderTokenRequest object.
   * @return {Promise<GrantProviderTokenResponse>} A Promise<GrantProviderTokenResponse> object.
   */
  public async grantProviderToken(params: GrantProviderTokenRequest,): Promise<GrantProviderTokenResponse> {
    return this.config.request<GrantProviderTokenResponse>(
      ApiUrls.PROVIDER_TOKEN_URL,
      {
        method: 'POST',
        body: params,
      },
    )
  }

  /**
   * Grant provider token.
   * @param {PatchProviderTokenRequest} params A PatchProviderTokenRequest object.
   * @return {Promise<PatchProviderTokenResponse>} A Promise<PatchProviderTokenResponse> object.
   */
  public async patchProviderToken(params: PatchProviderTokenRequest,): Promise<PatchProviderTokenResponse> {
    return this.config.request<PatchProviderTokenResponse>(
      ApiUrls.PROVIDER_TOKEN_URL,
      {
        method: 'PATCH',
        body: params,
      },
    )
  }

  /**
   * Signin with provider request.
   * @param {SignInWithProviderRequest} params A SignInWithProviderRequest object.
   * @return {Promise<Credentials>} A Promise<Credentials> object.
   */
  public async signInWithProvider(params: SignInWithProviderRequest,): Promise<Credentials> {
    const credentials: Credentials = await this.config.request<Credentials>(
      ApiUrls.AUTH_SIGN_IN_WITH_PROVIDER_URL,
      {
        method: 'POST',
        body: params,
      },
    )
    await this.config.credentialsClient.setCredentials(credentials)
    return Promise.resolve(credentials)
  }

  /**
   * Bind with provider
   * @param {BindWithProviderRequest} params A BindWithProviderRequest object.
   * @return {Promise<void>} A Promise<any> object.
   */
  public async bindWithProvider(params: BindWithProviderRequest,): Promise<void> {
    return this.config.request<any>(ApiUrls.PROVIDER_BIND_URL, {
      method: 'POST',
      body: params,
      withCredentials: true,
    })
  }

  /**
   * Get the user profile.
   * @return {Promise<UserProfile>} A Promise<UserProfile> object.
   */
  public async getUserProfile(): Promise<UserProfile> {
    return this.getUserInfo()
  }

  /**
   * Get the user info.
   * @return {Promise<UserInfo>} A Promise<UserProfile> object.
   */
  public async getUserInfo(): Promise<UserInfo> {
    const userInfo = await this.config.request<UserInfo>(ApiUrls.USER_ME_URL, {
      method: 'GET',
      withCredentials: true,
    })

    if (userInfo.sub) {
      userInfo.uid = userInfo.sub
    }

    return userInfo
  }

  /**
     * Delete me
     * @param params
     */
  public async deleteMe(params: WithSudoRequest): Promise<UserProfile> {
    const url = `${ApiUrls.USER_ME_URL}?${Auth.parseParamsToSearch(params)}`
    return this.config.request<UserProfile>(url, {
      method: 'DELETE',
      withCredentials: true,
    })
  }

  /**
   * hasLoginState check if has login state
   * @return {Promise<boolean>} A Promise<boolean> object.
   */
  public async hasLoginState(): Promise<boolean> {
    try {
      await this.config.credentialsClient.getAccessToken()
      return true
    } catch (error) {
      return false
    }
  }

  public hasLoginStateSync(): Credentials | null {
    const credentials = this.config.credentialsClient.getCredentialsSync()
    return credentials
  }

  public async getLoginState(): Promise<Credentials | null> {
    return this.config.credentialsClient.getCredentialsAsync()
  }

  /**
   * Trans by provider.
   * @param {TransByProviderRequest} params A TransByProviderRequest object.
   * @return {Promise<Credentials>} A Promise<Credentials> object.
   */
  public async transByProvider(params: TransByProviderRequest,): Promise<Credentials> {
    return this.config.request<Credentials>(
      ApiUrls.USER_TRANS_BY_PROVIDER_URL,
      {
        method: 'PATCH',
        body: params,
        withCredentials: true,
      },
    )
  }

  /**
   * Grant token.
   * @param {GrantTokenRequest} params A GrantTokenRequest object.
   * @return {Promise<Credentials>} A Promise<Credentials> object.
   */
  public async grantToken(params: GrantTokenRequest): Promise<Credentials> {
    return this.config.request<Credentials>(ApiUrls.AUTH_TOKEN_URL, {
      method: 'POST',
      body: params,
    })
  }

  /**
   * Get the provide list.
   * @return {Promise<UserProfileProvider>} A Promise<UserProfileProvider> object.
   */
  public async getProviders(): Promise<UserProfileProvider> {
    return this.config.request<UserProfileProvider>(ApiUrls.PROVIDER_LIST, {
      method: 'GET',
      withCredentials: true,
    })
  }

  /**
   * unbind provider.
   * @param {UnbindProviderRequest} params
   * @return {Promise<any>}
   */
  public async unbindProvider(params: UnbindProviderRequest): Promise<void> {
    return this.config.request<any>(
      `${ApiUrls.PROVIDER_UNBIND_URL}/${params.provider_id}`,
      {
        method: 'DELETE',
        withCredentials: true,
      },
    )
  }

  /**
   * check Password.
   * @param {CheckPasswordrRequest} params
   * @return {Promise<any>}
   */
  public async checkPassword(params: CheckPasswordrRequest): Promise<void> {
    return this.config.request<any>(`${ApiUrls.CHECK_PWD_URL}`, {
      method: 'POST',
      withCredentials: true,
      body: params,
    })
  }

  /**
   * check Password.
   * @param {CheckPasswordrRequest} params
   * @return {Promise<any>}
   */
  public async bindPhone(params: BindPhoneRequest): Promise<void> {
    return this.config.request<any>(`${ApiUrls.BIND_CONTACT_URL}`, {
      method: 'PATCH',
      withCredentials: true,
      body: params,
    })
  }

  /**
   * check Password.
   * @param {CheckPasswordrRequest} params
   * @return {Promise<any>}
   */
  public async bindEmail(params: BindEmailRequest): Promise<void> {
    return this.config.request<any>(`${ApiUrls.BIND_CONTACT_URL}`, {
      method: 'PATCH',
      withCredentials: true,
      body: params,
    })
  }

  /**
   * Set Password.
   * @param {SetPasswordrRequest} params
   * @return {Promise<any>}
   */
  public async setPassword(params: SetPasswordRequest): Promise<void> {
    return this.config.request<any>(`${ApiUrls.AUTH_SET_PASSWORD}`, {
      method: 'PATCH',
      withCredentials: true,
      body: params,
    })
  }

  /**
 * updatePasswordByOld 使用旧密码修改密码，如果已经绑定手机号，请先：sudo，再修改密码
 * @param {SetPasswordrRequest} params
 * @return {Promise<any>}
 */
  public async updatePasswordByOld(params: UpdatePasswordRequest): Promise<void> {
    const sudoToken = await this.sudo({ password: params.old_password })
    return this.setPassword({
      sudo_token: sudoToken.sudo_token,
      new_password: params.new_password,
    })
  }


  /**
   * sudo
   * @param {sudo} params
   * @return {Promise<any>}
   */
  public async sudo(params: SudoRequest): Promise<SudoResponse> {
    return this.config.request<SudoResponse>(`${ApiUrls.SUDO_URL}`, {
      method: 'POST',
      withCredentials: true,
      body: params,
    })
  }

  /**
   * Get the current user verification.
   * @param {GetVerificationRequest} params A GetVerificationRequest Object.
   * @return {Promise<GetVerificationResponse>} A Promise<GetVerificationResponse> object.
   */
  public async getCurUserVerification(params: GetVerificationRequest,): Promise<GetVerificationResponse> {
    params.target = 'CUR_USER'
    return this.config.request<GetVerificationResponse>(
      ApiUrls.VERIFICATION_URL,
      {
        method: 'POST',
        body: params,
        withCredentials: true,
        withCaptcha: true,
      },
    )
  }

  /**
   * change binded provider.
   * @param {GetVerificationRequest} params A GetVerificationRequest Object.
   * @return {Promise<GetVerificationResponse>} A Promise<GetVerificationResponse> object.
   */
  public async changeBindedProvider(params: ChangeBindedProviderRequest,): Promise<ChangeBindedProviderResponse> {
    return this.config.request<ChangeBindedProviderResponse>(
      `${ApiUrls.PROVIDER_LIST}/${params.provider_id}/trans`,
      {
        method: 'POST',
        body: {
          provider_trans_token: params.trans_token,
        },
        withCredentials: true,
      },
    )
  }

  /**
   * Patch the user profile.
   * @param {UserProfile} params A UserProfile Object.
   * @return {Promise<UserProfile>} A Promise<UserProfile> object.
   */
  public async setUserProfile(params: UserProfile): Promise<UserProfile> {
    return this.config.request<UserProfile>(ApiUrls.USER_PRIFILE_URL, {
      method: 'PATCH',
      body: params,
      withCredentials: true,
    })
  }

  /**
   * Patch the user profile.
   * @param {QueryUserProfileReq} appended_params A QueryUserProfileReq Object.
   * @return {Promise<UserProfile>} A Promise<UserProfile> object.
   */
  public async queryUserProfile(params: QueryUserProfileRequest,): Promise<QueryUserProfileResponse> {
    // let url = new URL(ApiUrls.USER_QUERY_URL);
    const searchParams = new URLSearchParams(params as any)
    // url.search = searchParams.toString();
    return this.config.request<QueryUserProfileResponse>(`${ApiUrls.USER_QUERY_URL}?${searchParams.toString()}`, {
      method: 'GET',
      withCredentials: true,
    })
  }

  /**
   * setCustomSignFunc set the get ticket function
   * @param getTickFn
   */
  public setCustomSignFunc(getTickFn: GetCustomSignTicketFn) {
    this.getCustomSignTicketFn = getTickFn
  }

  /**
   * SignInWithCustomTicket custom signIn
   * @constructor
   */
  public async signInWithCustomTicket(): Promise<Credentials> {
    const customTicket = await this.getCustomSignTicketFn()
    return this.signInWithProvider({
      provider_id: 'custom',
      provider_token: customTicket,
    })
  }

  /**
   * Reset password
   * @param {ResetPasswordRequest} params
   * @returns {Promise<void>}
   * @memberof Auth
   */
  public async resetPassword(params: ResetPasswordRequest): Promise<void> {
    return this.config.request(ApiUrls.AUTH_RESET_PASSWORD, {
      method: 'POST',
      body: params,
      // withCredentials: true
    })
  }

  /**
   * device authorization
   * @param {DeviceAuthorizeRequest} params
   * @returns {Promise<DeviceAuthorizeResponse>}
   * @memberof Auth
   */
  public async deviceAuthorize(params: DeviceAuthorizeRequest): Promise<DeviceAuthorizeResponse> {
    return this.config.request(ApiUrls.AUTH_GET_DEVICE_CODE, {
      method: 'POST',
      body: params,
      withCredentials: true,
    })
  }

  public async checkUsername(params: CheckUsernameRequest): Promise<void> {
    return this.config.request(ApiUrls.CHECK_USERNAME, {
      method: 'GET',
      body: params,
      withCredentials: true,
    })
  }

  public async checkIfUserExist(params: CheckIfUserExistRequest): Promise<CheckIfUserExistResponse> {
    const searchParams = new URLSearchParams(params as any)

    return this.config.request<CheckIfUserExistResponse>(`${ApiUrls.CHECK_IF_USER_EXIST}?${searchParams.toString()}`, {
      method: 'GET',
    })
  }

  public async loginScope(): Promise<string> {
    return this.config.credentialsClient.getScope()
  }

  public async loginGroups(): Promise<string[]> {
    return this.config.credentialsClient.getGroups()
  }
}
