export enum Syntax {
  CLIENT_ID = 'client_id',
  CLIENT_SECRET = 'client_secret',
  RESPONSE_TYPE = 'response_type',
  SCOPE = 'scope',
  STATE = 'state',
  REDIRECT_URI = 'redirect_uri',
  ERROR = 'error',
  ERROR_DESCRIPTION = 'error_description',
  ERROR_URI = 'error_uri',
  GRANT_TYPE = 'grant_type',
  CODE = 'code',
  ACCESS_TOKEN = 'access_token',
  TOKEN_TYPE = 'token_type',
  EXPIRES_IN = 'expires_in',
  USERNAME = 'username',
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
}

export enum ErrorType {
  UNREACHABLE = 'unreachable',
  LOCAL = 'local',
  CANCELLED = 'cancelled',
  UNKNOWN = 'unknown',
  INVALID_ARGUMENT = 'invalid_argument',
  DEADLINE_EXCEEDED = 'deadline_exceeded',
  NOT_FOUND = 'not_found',
  ALREADY_EXISTS = 'already_exists',
  PERMISSION_DENIED = 'permission_denied',
  UNAUTHENTICATED = 'unauthenticated',
  RESOURCE_EXHAUSTED = 'resource_exhausted',
  FAILED_PRECONDITION = 'failed_precondition',
  ABORTED = 'aborted',
  OUT_OF_RANGE = 'out_of_range',
  UNIMPLEMENTED = 'unimplemented',
  INTERNAL = 'internal',
  UNAVAILABLE = 'unavailable',
  DATA_LOSS = 'data_loss',
  // CommonError
  CAPTCHA_REQUIRED = 'captcha_required',
  CAPTCHA_INVALID = 'captcha_invalid',
  INVALID_PASSWORD = 'invalid_password',
  INVALID_STATUS = 'invalid_status',
  USER_PENDING = 'user_pending',
  USER_BLOCKED = 'user_blocked',
  INVALID_VERIFICATION_CODE = 'invalid_verification_code',
  TWO_FACTOR_REQUIRED = 'two_factor_required',
  INVALID_TWO_FACTOR = 'invalid_two_factor',
  INVALID_TWO_FACTOR_RECOVERY = 'invalid_two_factor_recovery',
  UNDER_REVIEW = 'under_review',
  INVALID_REQUEST = 'invalid_request',
  UNAUTHORIZED_CLIENT = 'unauthorized_client',
  ACCESS_DENIED = 'access_denied',
  UNSUPPORTED_RESPONSE_TYPE = 'unsupported_response_type',
  INVALID_SCOPE = 'invalid_scope',
  INVALID_GRANT = 'invalid_grant',
  SERVER_ERROR = 'server_error',
  TEMPORARILY_UNAVAILABLE = 'temporarily_unavailable',
  INTERACTION_REQUIRED = 'interaction_required',
  LOGIN_REQUIRED = 'login_required',
  ACCOUNT_SELECTION_REQUIRED = 'account_selection_required',
  CONSENT_REQUIRED = 'consent_required',
  INVALID_REQUEST_URI = 'invalid_request_uri',
  INVALID_REQUEST_OBJECT = 'invalid_request_object',
  REQUEST_NOT_SUPPORTED = 'request_not_supported',
  REQUEST_URI_NOT_SUPPORTED = 'request_uri_not_supported',
  REGISTRATION_NOT_SUPPORTED = 'registration_not_supported',
}
