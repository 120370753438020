import { ErrorType } from './consts'

import { AuthClient, SimpleStorage } from './interface'

import {
  Credentials,
  ResponseError,
  RequestOptions,
  RequestFunction,
  OAuth2ClientOptions,
  AuthClientRequestOptions,
} from './models'

import { uuidv4 } from '../utils/uuid'

import { SinglePromise } from '../utils/function/single-promise'

const RequestIdHeaderName = 'x-request-id'
const DeviceIdHeaderName = 'x-device-id'
const DeviceIdSectionName = 'device_id'

export interface ToResponseErrorOptions {
  error?: ErrorType;
  error_description?: string | null;
  error_uri?: string | null;
  details?: any | null;
}


export const defaultRequest: RequestFunction = async function <T>(
  url: string,
  options?: RequestOptions
): Promise<T> {
  let result: T | null = null
  let responseError: ResponseError | null = null
  try {
    // Objects must be copied to prevent modification of data such as body.
    const copyOptions = Object.assign({}, options)
    if (!copyOptions.method) {
      copyOptions.method = 'GET'
    }
    if (copyOptions.body && typeof copyOptions.body !== 'string') {
      copyOptions.body = JSON.stringify(copyOptions.body)
    }
    const responseResult: Response = await fetch(url, copyOptions)
    const jsonResponse = await responseResult.json()
    if (jsonResponse?.error) {
      responseError = jsonResponse as ResponseError
      responseError.error_uri = new URL(url).pathname
    } else {
      result = jsonResponse as T
    }
  } catch (error) {
    responseError = {
      error: ErrorType.UNREACHABLE,
      error_description: error.message,
      error_uri: new URL(url).pathname,
    }
  }
  if (responseError) {
    throw responseError
  } else {
    return result
  }
}

export const toResponseError = (
  error: ResponseError | Error,
  options?: ToResponseErrorOptions,
): ResponseError => {
  let responseError: ResponseError
  const formatOptions: ToResponseErrorOptions = options || {}
  if (error instanceof Error) {
    responseError = {
      error: formatOptions.error || ErrorType.LOCAL,
      error_description: formatOptions.error_description || error.message,
      error_uri: formatOptions.error_uri,
      details: formatOptions.details || error.stack,
    }
  } else {
    const formatError: ToResponseErrorOptions = error || {}
    responseError = {
      error: formatOptions.error || formatError.error || ErrorType.LOCAL,
      error_description:
        formatOptions.error_description || formatError.error_description,
      error_uri: formatOptions.error_uri || formatError.error_uri,
      details: formatOptions.details || formatError.details,
    }
  }
  return responseError
}

/**
 * Generate request id.
 * @return {string}
 */
export function generateRequestId(): string {
  return uuidv4()
}


/**
 * Default Storage.
 */
class DefaultStorage implements SimpleStorage {
  /**
     * Get item.
     * @param {string} key
     */
  async getItem(key: string): Promise<string | null> {
    return window.localStorage.getItem(key)
  }

  /**
     * Remove item.
     * @param {string} key
     */
  async removeItem(key: string): Promise<void> {
    window.localStorage.removeItem(key)
  }

  /**
     * Set item.
     * @param {string} key
     * @param {string} value
     */
  async setItem(key: string, value: string): Promise<void> {
    window.localStorage.setItem(key, value)
  }

  /**
     * Get item sync.
     * @param {string} key
     */
  getItemSync(key: string): string | null {
    return window.localStorage.getItem(key)
  }

  /**
     * Remove item sync.
     * @param {string} key
     */
  removeItemSync(key: string): void {
    window.localStorage.removeItem(key)
  }

  /**
     * Set item sync.
     * @param {string} key
     * @param {string} value
     */
  setItemSync(key: string, value: string): void {
    window.localStorage.setItem(key, value)
  }
}

export const defaultStorage = new DefaultStorage()

interface LocalCredentialsOptions {
  tokenSectionName: string;
  storage: SimpleStorage;
}

/**
 * Check if credentials is expired.
 * @param {Credentials} credentials
 * @return {boolean}
 */
function isCredentialsExpired(credentials: Credentials): boolean {
  let isExpired = true
  if (credentials?.expires_at && credentials?.access_token) {
    isExpired = credentials.expires_at < new Date()
  }
  return isExpired
}

/**
 * Local credentials.
 * Local credentials, with memory cache and storage cache.
 * If the memory cache expires, the storage cache is automatically loaded.
 */
export class LocalCredentials {
  private tokenSectionName: string

  private storage: SimpleStorage

  private credentials: Credentials | null = null

  private singlePromise: SinglePromise = new SinglePromise()

  /**
   * constructor
   * @param {LocalCredentialsOptions} options
   */
  constructor(options: LocalCredentialsOptions) {
    this.tokenSectionName = options.tokenSectionName
    this.storage = options.storage
  }

  public getStorageCredentialsSync(): Credentials | null {
    let credentials: Credentials = null
    const tokenStr: string = this.storage.getItemSync(this.tokenSectionName,)
    if (tokenStr !== undefined && tokenStr !== null) {
      try {
        credentials = JSON.parse(tokenStr)
        if (credentials?.expires_at) {
          credentials.expires_at = new Date(credentials.expires_at)
        }
      } catch (error) {
        this.storage.removeItem(this.tokenSectionName)
        credentials = null
      }
    }
    return credentials
  }

  /**
   * setCredentials Provides an alternative fetch api request implementation with auth credentials
   * @param {Credentials} credentials
   */
  public async setCredentials(credentials?: Credentials): Promise<void> {
    if (credentials?.expires_in) {
      credentials.expires_at = new Date(Date.now() + (credentials.expires_in - 30) * 1000,)
      if (this.storage) {
        const tokenStr: string = JSON.stringify(credentials)
        await this.storage.setItem(this.tokenSectionName, tokenStr)
      }
      this.credentials = credentials
    } else {
      if (this.storage) {
        await this.storage.removeItem(this.tokenSectionName)
      }
      this.credentials = null
    }
  }

  /**
   * Get credentials.
   * @return {Promise<Credentials | null>}
   */
  public async getCredentials(): Promise<Credentials | null> {
    return this.singlePromise.run('getCredentials', async () => {
      if (isCredentialsExpired(this.credentials)) {
        this.credentials = await this.getStorageCredentials()
      }
      return this.credentials
    })
  }

  /**
   * Get storage credentials.
   */
  private async getStorageCredentials(): Promise<Credentials | null> {
    return this.singlePromise.run('_getStorageCredentials', async () => {
      let credentials: Credentials = null
      const tokenStr: string = await this.storage.getItem(this.tokenSectionName,)
      if (tokenStr !== undefined && tokenStr !== null) {
        try {
          credentials = JSON.parse(tokenStr)
          if (credentials?.expires_at) {
            credentials.expires_at = new Date(credentials.expires_at)
          }
        } catch (error) {
          await this.storage.removeItem(this.tokenSectionName)
          credentials = null
        }
      }
      return credentials
    })
  }
}

/**
 * OAuth2Client
 */
export class OAuth2Client implements AuthClient {
  private static defaultRetry = 2
  private static minRetry = 0
  private static maxRetry = 5
  private static retryInterval = 1000

  private apiOrigin: string
  private clientId: string
  private retry: number
  private clientSecret?: string
  private baseRequest: <T>(
    url: string,
    options?: RequestOptions,
  ) => Promise<T>
  private localCredentials: LocalCredentials
  private storage: SimpleStorage
  private deviceID?: string
  private tokenInURL?: boolean
  private refreshTokenFunc: (refreshToken?: string) => Promise<Credentials>
  private headers?: { [key: string]: string }
  private singlePromise: SinglePromise = new SinglePromise()
  private anonymousSignInFunc: (Credentials) => Promise<Credentials | void>

  /**
   * constructor
   * @param {OAuth2ClientOptions} options
   */
  constructor(options: OAuth2ClientOptions) {
    this.apiOrigin = options.apiOrigin
    this.clientId = options.clientId
    this.retry = this.formatRetry(options.retry, OAuth2Client.defaultRetry)
    if (options.baseRequest) {
      this.baseRequest = options.baseRequest
    } else {
      this.baseRequest = defaultRequest
    }
    this.tokenInURL = options.tokenInURL
    this.headers = options.headers
    // @ts-ignore
    this.storage = options.storage || defaultStorage
    this.localCredentials = new LocalCredentials({
      tokenSectionName: `credentials_${options.clientId}`,
      storage: this.storage,
    })
    this.clientSecret = options.clientSecret
    this.refreshTokenFunc =      options.refreshTokenFunc || this.defaultRefreshTokenFunc
    this.anonymousSignInFunc = options.anonymousSignInFunc
  }

  /**
   * setCredentials Provides an alternative fetch api request implementation with auth credentials
   * @param {Credentials} credentials
   * @return {Promise<void>}
   */
  public setCredentials(credentials?: Credentials): Promise<void> {
    return this.localCredentials.setCredentials(credentials)
  }

  /**
   * getAccessToken return a validate access token
   */
  public async getAccessToken(): Promise<string> {
    const credentials: Credentials = await this.getCredentials()
    if (credentials?.access_token) {
      return Promise.resolve(credentials.access_token)
    }
    const respErr: ResponseError = { error: ErrorType.UNAUTHENTICATED }
    return Promise.reject(respErr)
  }

  /**
   * request http like simple fetch api, exp:request('/v1/user/me', {withCredentials:true})
   * @param {string} url
   * @param {AuthClientRequestOptions} options
   */
  public async request<T>(
    url: string,
    options?: AuthClientRequestOptions,
  ): Promise<T> {
    if (!options) {
      options = {}
    }
    const retry: number = this.formatRetry(options.retry, this.retry)
    options.headers = options.headers || {}
    if (this.headers) {
      options.headers = {
        ...this.headers,
        ...options.headers,
      }
    }
    if (!options.headers[RequestIdHeaderName]) {
      options.headers[RequestIdHeaderName] = generateRequestId()
    }
    if (!options.headers[DeviceIdHeaderName]) {
      const deviceId = await this.getDeviceId()
      options.headers[DeviceIdHeaderName] = deviceId
    }
    if (options?.withCredentials) {
      const credentials = await this.getCredentials()
      if (credentials) {
        if (this.tokenInURL) {
          if (url.indexOf('?') < 0) {
            url += '?'
          }
          url += `access_token=${credentials.access_token}`
        } else {
          options.headers.Authorization =            `${credentials.token_type} ${credentials.access_token}`
        }
      }
    } else {
      if (this.clientId && url.indexOf('client_id') < 0) {
        url += url.indexOf('?') < 0 ? '?' : '&'
        url += `client_id=${this.clientId}`
      }
    }
    if (url.startsWith('/')) {
      url = this.apiOrigin + url
    }
    let response: T | null = null
    const maxRequestTimes: number = retry + 1
    for (
      let requestTime = 0;
      requestTime < maxRequestTimes;
      requestTime++
    ) {
      try {
        response = await this.baseRequest<T>(url, options)
        break
      } catch (responseError) {
        if (options.withCredentials && responseError && responseError.error === ErrorType.UNAUTHENTICATED) {
          await this.setCredentials(null)
          return Promise.reject(responseError)
        }

        if (
          requestTime === retry
          || !responseError
          || responseError.error !== 'unreachable'
        ) {
          return Promise.reject(responseError)
        }
      }
      await this.sleep(OAuth2Client.retryInterval)
    }
    return response
  }


  /**
   * Get credentials.
   */
  public async getCredentials(): Promise<Credentials | null> {
    let credentials: Credentials = await this.localCredentials.getCredentials()
    if (isCredentialsExpired(credentials)) {
      if (credentials && credentials.scope === 'anonymous') {
        if (this.anonymousSignInFunc) {
          const c = await this.anonymousSignInFunc(credentials)
          credentials = c || await this.localCredentials.getCredentials()
        } else {
          credentials = await this.anonymousSignIn(credentials)
        }
      } else {
        credentials = await this.refreshToken(credentials)
      }
    }
    return credentials
  }

  public getCredentialsSync(): Credentials | null {
    const credentials: Credentials = this.localCredentials.getStorageCredentialsSync()
    return credentials
  }

  public getCredentialsAsync(): Promise<Credentials | null> {
    return this.localCredentials.getCredentials()
  }

  public async getScope(): Promise<string> {
    const credentials: Credentials = await this.localCredentials.getCredentials()
    if (!credentials) {
      return this.unAuthenticatedError('credentials not found')
    }
    return credentials.scope
  }

  public async getGroups(): Promise<string[]> {
    const credentials: Credentials = await this.localCredentials.getCredentials()
    if (!credentials) {
      return this.unAuthenticatedError('credentials not found')
    }
    return credentials.groups
  }

  /**
   * Check retry value.
   * @param {number} retry
   * @return {number}
   */
  private checkRetry(retry: number): number {
    let responseError: ResponseError | null = null
    if (
      typeof retry !== 'number'
      || retry < OAuth2Client.minRetry
      || retry > OAuth2Client.maxRetry
    ) {
      responseError = {
        error: ErrorType.UNREACHABLE,
        error_description: 'wrong options param: retry',
      }
    }
    if (responseError) {
      throw responseError
    }
    return retry
  }

  /**
   * Format retry value.
   * @param {number} retry
   * @param {number} defaultVale
   * @return {number}
   */
  private formatRetry(retry: number, defaultVale: number): number {
    if (typeof retry === 'undefined') {
      return defaultVale
    }
    return this.checkRetry(retry)
  }

  /**
   * Sleep.
   * @param {number} ms
   * @return {Promise<void>}
   */
  private async sleep(ms: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }

  /**
   * Refresh expired token.
   * @param {Credentials} credentials
   * @return {Promise<Credentials>}
   */
  private async refreshToken(credentials: Credentials): Promise<Credentials> {
    return this.singlePromise.run('_refreshToken', async () => {
      if (!credentials || !credentials.refresh_token) {
        return this.unAuthenticatedError('no refresh token found in credentials')
      }
      try {
        const newCredentials: Credentials = await this.refreshTokenFunc(credentials.refresh_token,)
        await this.localCredentials.setCredentials(newCredentials)
        return newCredentials
      } catch (error) {
        if (error.error === ErrorType.INVALID_GRANT) {
          await this.localCredentials.setCredentials(null)
          return this.unAuthenticatedError(error.error_description)
        }
        return Promise.reject(error)
      }
    })
  }

  /**
   * anonymous signIn
   * @param {Credentials} credentials
   * @return {Promise<Credentials>}
   */
  private async anonymousSignIn(credentials: Credentials): Promise<Credentials> {
    return this.singlePromise.run('_anonymous', async () => {
      if (!credentials || credentials.scope !== 'anonymous') {
        return this.unAuthenticatedError('no anonymous in credentials')
      }
      try {
        const newCredentials: Credentials = await this.request('/auth/v1/signin/anonymously', {
          method: 'POST',
          body: {
            client_id: this.clientId,
            client_secret: this.clientSecret,
          },
        })
        await this.localCredentials.setCredentials(newCredentials)
        return newCredentials
      } catch (error) {
        if (error.error === ErrorType.INVALID_GRANT) {
          await this.localCredentials.setCredentials(null)
          return this.unAuthenticatedError(error.error_description)
        }
        return Promise.reject(error)
      }
    })
  }

  /**
   * Default refresh token function.
   * @param {string} refreshToken
   * @return {Promise<Credentials>}
   */
  private defaultRefreshTokenFunc(refreshToken?: string,): Promise<Credentials> {
    if (refreshToken === undefined || refreshToken === '') {
      return this.unAuthenticatedError('refresh token not found')
    }
    return this.request('/auth/v1/token', {
      method: 'POST',
      body: {
        client_id: this.clientId,
        client_secret: this.clientSecret,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
    })
  }

  /**
   * Get deviceId
   */
  private async getDeviceId(): Promise<string> {
    if (this.deviceID) {
      return this.deviceID
    }
    let deviceId: string = await this.storage.getItem(DeviceIdSectionName,)
    if (!(typeof deviceId === 'string'
      && deviceId.length >= 16
      && deviceId.length <= 48)) {
      deviceId = uuidv4()
      await this.storage.setItem(DeviceIdSectionName, deviceId)
    }
    this.deviceID = deviceId
    return deviceId
  }
  /**
   * Generate unAuthenticated error.
   * @param {string} err
   * @return {Promise<T>}
   */
  private unAuthenticatedError<T>(err?: string): Promise<T> {
    const respErr: ResponseError = {
      error: ErrorType.UNAUTHENTICATED,
      error_description: err,
    }
    return Promise.reject(respErr)
  }
}
