import { KV } from '@cloudbase/types'
import { cache } from '@cloudbase/utilities'
import { ICloudbaseCache, ICacheConfig } from '@cloudbase/types/cache'

const USER_INFO_KEY = 'user_info'

const { CloudbaseCache } = cache

const cacheMap: KV<ICloudbaseCache> = {}
// 本地存储
const localCacheMap: KV<ICloudbaseCache> = {}

export function initCache(config: ICacheConfig & { env: string }) {
  const { env, platformInfo } = config

  const userInfoKey = `${USER_INFO_KEY}_${env}`

  const keys = {
    userInfoKey,
  }
  // 若指定env已存在cache则尝试更新persistence
  cacheMap[env] = cacheMap[env] || (new CloudbaseCache({
    ...config,
    keys,
    platformInfo,
  }))
  localCacheMap[env] = localCacheMap[env] || new CloudbaseCache({
    ...config,
    keys,
    platformInfo,
    persistence: 'local',
  })
}

export function getCacheByEnvId(env: string): ICloudbaseCache {
  return cacheMap[env]
}

export function getLocalCache(env: string): ICloudbaseCache {
  return localCacheMap[env]
}
